<template>
  <component
    v-if="scalePlanTemplate"
    :is="scalePlanTemplate"
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.fact')"
    :data="scope.row.scale"
    @change="saveElementPropChange(scope.row, 'scale', {fact:$event})"
  ></component>
  <div v-else style="color: #b1b1b2;">Сначала необходимо выбрать шкалу</div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-configurable-scale-fact-table-column',
  mixins: [ElementPropTableColumn],
  components: {ClickToEdit},

  props: {},
  data() {
    return {

    }
  },
  computed: {
    scalePlanTemplate() {
      if( !this.scope.row?.scale?.template?.type ){
        return null;
      }
      return this.$companyConfiguration.scales.getConfigurableScaleTemplate(this.scope.row.scale.template.type, 'cardFactView');
    },
  },
  methods: {}
}

</script>

<style>

</style>
